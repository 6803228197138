import React, { useState, useEffect } from "react";
import { Upload, FileText, Check, AlertTriangle, LogOut } from "lucide-react";
import axios from "axios";

// const API_URI = "http://localhost:8000";
const API_URI = '/api/'

const Alert = ({ children, variant }) => {
  const baseClasses = "p-4 mb-4 rounded-lg flex items-center";
  const variantClasses = {
    success: "bg-green-100 border border-green-400 text-green-700",
    error: "bg-red-100 border border-red-400 text-red-700",
  };

  return (
    <div className={`${baseClasses} ${variantClasses[variant]}`}>
      {children}
    </div>
  );
};

const Dashboard = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const token = localStorage.getItem("token");
  // console.log(token);

  useEffect(() => {
    const storedCollection = localStorage.getItem("collection");
    if (storedCollection) {
      window.location.href = "/chatdoc"; // Redirect if collection not found
    }
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadStatus(null);
  };

    const handleLogout = () => {
      // Clear the collection or any other user-related data
      localStorage.removeItem("collection");
      localStorage.removeItem("token");
      window.location.href = "/login";
    };

  const handleUpload = async () => {
    if (selectedFile) {
      setIsLoading(true);
      // Simulating upload process
      const formData = new FormData();
      formData.append("file", selectedFile);

      try {
        const response = await axios.post(
          API_URI + "/api/file-upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": `Bearer ${token}`,
            },
          }
        );
        // console.log(response.data);
        const { collection } = response.data;
        localStorage.setItem("collection", collection);
        setUploadStatus("success");
        setIsLoading(false);
        window.location.href = "/chatdoc";
      } catch (error) {
        console.error(error);
        setUploadStatus("error");
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    document.body.style.fontFamily = "'Inter', sans-serif";
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div className="flex flex-col h-screen bg-gray-100 font-inter">
      <header className="bg-gradient-to-r from-purple-600 to-indigo-600 shadow-lg">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white">Document Chat</h1>
          <button
            onClick={handleLogout}
            className="flex items-center text-white hover:text-gray-200 transition-colors px-2"
          >
            Log Out
            <LogOut className="h-5 w-5 ml-2" />
          </button>
        </div>
      </header>

      <main className="flex-grow mt-6">
        <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
          <h1 className="text-2xl font-bold mb-6 text-center text-gray-800">
            Load Documents to the ForgeGPT
          </h1>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Upload Document:
            </label>
            <div className="flex items-center justify-center w-full">
              <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <FileText className="w-10 h-10 mb-3 text-gray-400" />
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500">PDF (MAX. 10MB)</p>
                </div>
                <input
                  type="file"
                  className="hidden"
                  accept="application/pdf"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          </div>

          {selectedFile && (
            <p className="text-sm text-gray-600 mb-4">
              Selected file: {selectedFile.name}
            </p>
          )}

          <button
            onClick={handleUpload}
            disabled={isLoading || !selectedFile}
            className={`w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white ${
              isLoading || !selectedFile
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700"
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
          >
            {isLoading ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Uploading...
              </>
            ) : (
              <>
                <Upload className="w-5 h-5 mr-2" />
                Upload PDF
              </>
            )}
          </button>

          {uploadStatus === "success" && (
            <Alert variant="success">
              <Check className="w-4 h-4 mr-2" />
              <span>File uploaded successfully!</span>
            </Alert>
          )}

          {uploadStatus === "error" && (
            <Alert variant="error">
              <AlertTriangle className="w-4 h-4 mr-2" />
              <span>Please select a file before uploading.</span>
            </Alert>
          )}
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
