import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import * as Dialog from "@radix-ui/react-dialog";
import { styled } from "@stitches/react";
import { useNavigate } from "react-router-dom";

const StyledOverlay = styled(Dialog.Overlay, {
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  position: "fixed",
  inset: 0,
});

const StyledContent = styled(Dialog.Content, {
  backgroundColor: "white",
  borderRadius: "6px",
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxWidth: "450px",
  maxHeight: "85vh",
  padding: "25px",
  fontFamily: "'Inter', sans-serif",
});

const StyledTitle = styled(Dialog.Title, {
  margin: 0,
  font: "Inter",
  fontWeight: 500,
  color: "black",
  fontSize: "17px",
});

const StyledButton = styled("button", {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  padding: "0 15px",
  fontSize: "15px",
  lineHeight: 1,
  fontWeight: 500,
  height: "35px",
  width: "100%",
  backgroundColor: "#4285F4",
  color: "white",
  border: "none",
  cursor: "pointer",
  "&:hover": { backgroundColor: "#3367D6" },
  "&:focus": { boxShadow: "0 0 0 2px black" },
  fontFamily: "'Inter', sans-serif",
});

const CenteredContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  fontFamily: "'Inter', sans-serif",
});

const OpenSignInButton = styled("button", {
  padding: "10px 20px",
  fontSize: "16px",
  fontWeight: "bold",
  backgroundColor: "black",
  color: "white",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  fontFamily: "'Inter', sans-serif",
  "&:hover": {
    backgroundColor: "#3367D6",
  },
});

const API_URI = "/api/auth/google"

export default function GoogleSignIn() {
  const navigate = useNavigate();

  const handleLoginSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;

    try {
      const response = await axios.post(API_URI, {
        token,
      });

      const { access_token, user } = response.data;
      // console.log(access_token);
      localStorage.setItem("token", access_token);

      navigate("/dashboard");
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  };

  const handleLoginFailure = (error) => {
    console.error("Google Login Failed:", error);
  };

  return (
    <CenteredContainer>
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <OpenSignInButton>Sign In with Google</OpenSignInButton>
        </Dialog.Trigger>
        <Dialog.Portal>
          <StyledOverlay />
          <StyledContent>
            {/* <StyledTitle>Sign In</StyledTitle> */}
            <GoogleLogin
              onSuccess={handleLoginSuccess}
              onError={handleLoginFailure}
              useOneTap
              render={({ onClick, disabled }) => (
                <StyledButton onClick={onClick} disabled={disabled}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    style={{ marginRight: "10px" }}
                  >
                    <path
                      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                      fill="#4285F4"
                    />
                    <path
                      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                      fill="#EA4335"
                    />
                    <path d="M1 1h22v22H1z" fill="none" />
                  </svg>
                  Sign in with Google
                </StyledButton>
              )}
            />
            <Dialog.Close asChild>
              <button
                aria-label="Close"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                ✕
              </button>
            </Dialog.Close>
          </StyledContent>
        </Dialog.Portal>
      </Dialog.Root>
    </CenteredContainer>
  );
}
