import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./index.css";
import App from "./App";
import GoogleSignIn from "./GoogleSignIn";
import Dashboard from "./Dashboard";
import Chat from "./Chat";

const root = ReactDOM.createRoot(document.getElementById("root"));

const clientId =
  "859138008196-9b3ojpsl4n4ocdmg5bor8hsth4v0tvib.apps.googleusercontent.com"; // Replace with your Google client ID

function PrivateRoute({ children }) {
  const isAuthenticated = localStorage.getItem("token") !== null;
  return isAuthenticated ? children : <Navigate to="/auth" replace />;
}

function AuthRoute({ children }) {
  const isAuthenticated = localStorage.getItem("token") !== null;
  return isAuthenticated ? <Navigate to="/dashboard" replace /> : children;
}

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
      <Router>
        <Routes>
          <Route
            path="/auth"
            element={
              <AuthRoute>
                <GoogleSignIn />
              </AuthRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path="/tryforge" element={<App />} />
          <Route path="/chatdoc" element={<Chat />} />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
